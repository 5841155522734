import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-base text-gray-800`}

  p {
    ${tw`mt-0 leading-loose`}
  }
  
  h1 {
    ${tw`text-2xl font-bold mt-10 lg:text-3xl`}
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`}
  }

  h3 {
    ${tw`text-lg font-bold mt-6 lg:text-xl`}
  }

  ul {
    ${tw`list-disc list-inside`}

    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  ${tw`sm:text-sm md:text-base lg:text-lg`}
`;

const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t('privacy.title')}</Heading>
          </HeadingRow>
          <Text>
            <h2>{t('privacy.collection.title')}</h2>
            <p>{t('privacy.collection.description')}</p>
            <ul>
              {t('privacy.collection.items', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <h2>{t('privacy.usage.title')}</h2>
            <p>{t('privacy.usage.description')}</p>
            <ul>
              {t('privacy.usage.items', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <h2>{t('privacy.storage.title')}</h2>
            <p>{t('privacy.storage.description')}</p>

            <h2>{t('privacy.thirdParty.title')}</h2>
            <p>{t('privacy.thirdParty.description')}</p>
            <ul>
              {t('privacy.thirdParty.items', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <h2>{t('privacy.cookies.title')}</h2>
            <p>{t('privacy.cookies.description')}</p>

            <h2>{t('privacy.analytics.title')}</h2>
            <p>{t('privacy.analytics.description')}</p>

            <h2>{t('privacy.rights.title')}</h2>
            <p>{t('privacy.rights.description')}</p>

            <h2>{t('privacy.minors.title')}</h2>
            <p>{t('privacy.minors.description')}</p>

            <h2>{t('privacy.changes.title')}</h2>
            <p>{t('privacy.changes.description')}</p>

            <h2>{t('privacy.contact.title')}</h2>
            <p>{t('privacy.contact.description')}</p>
            <p>{t('privacy.contact.company')}</p>
            <p>{t('privacy.contact.address')}</p>
            <p>{t('privacy.contact.email')}</p>
            <p>{t('privacy.contact.emailNote1')}</p>
            <p>{t('privacy.contact.emailNote2')}</p>

            <h2>{t('privacy.jurisdiction.title')}</h2>
            <p>{t('privacy.jurisdiction.description')}</p>
            <br />
            <p>{t('privacy.effectiveDate', { date: '2024-10-08' })}</p>
          </Text>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};