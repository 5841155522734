import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ScrollToTop from "./ScrollToTop";
import GlobalStyles from "./styles/GlobalStyles";
import ConotyLp from "./design/ConotyLp";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import UserPrivacyChoicePage from "./pages/UserPrivacyChoice";
import LegalNoticePage from "./pages/LegalNotice";
import ContactUsPage from "./pages/ContactUs";
import TermsOfServicePage from "./pages/TermsOfService";
import TermsOfServicePremiumPage from "./pages/TermsOfServicePremium";
import AboutUsPage from "./pages/AboutUs";
import UserSupportPage from "./pages/UserSupport";
import UserSupportPageForApp from "./pages/UserSupportForApp";
import FaqsPage from "./pages/Faqs";
import MaintenancePage from "./pages/Maintenance";
import HowToUseListPage from "./pages/HowToUseList";

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <GlobalStyles />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<ConotyLp />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/user-privacy-choice" element={<UserPrivacyChoicePage />} />
            <Route path="/legal-notice" element={<LegalNoticePage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/terms-of-service-premium" element={<TermsOfServicePremiumPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/user-support" element={<UserSupportPage />} />
            <Route path="/user-support-for-app" element={<UserSupportPageForApp />} />
            <Route path="/faqs" element={<FaqsPage />} />
            <Route path="/maintenance" element={<MaintenancePage />} />
            <Route path="/howtouse" element={<HowToUseListPage />} />
          </Routes>
        </Router>
      </Suspense>
    </I18nextProvider>
  );
};

export default App;