import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import styled, { keyframes } from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundImage from "images/conoty-lp-top/conoty-lp-top.png";
import logoImage from "images/conoty-lp-top/conoty-logo-square-wh.png";
import messageImageJa from "images/conoty-lp-top/conoty-top-message-ja.png";
import messageImageEn from "images/conoty-lp-top/conoty-top-message-en.png";
import wideLogo from "images/conoty-lp-top/conoty-logo-wide.png";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const InitialLogoContainer = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50`}
  animation: ${fadeIn} 1s ease-out forwards, 
             ${props => props.fadeOut ? css`${fadeOut} 1s ease-out forwards` : 'none'};
  animation-delay: 0s, 1s;
`;

const InitialLogo = styled.img`
  ${tw`w-1/2 max-w-md`}
`;

const Container = styled.div`
  ${tw`relative bg-center bg-cover h-screen min-h-144`}
  background-image: url(${backgroundImage});
  opacity: 0;
  animation: ${props => props.visible ? css`${fadeIn} 1s ease-out forwards` : 'none'};
  animation-delay: 2s;
`;

const HeroContainer = tw.div`relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const ImageContainer = styled.div`
  ${tw`flex flex-col md:flex-row justify-center items-center w-full`}
`;

const ImageWrapper = styled.div`
  ${tw`mx-16`}
`;

const Message = styled.img`
  ${tw`w-full max-w-md`}
`;

const Logo = styled(Message)`
  ${tw`w-full`}
`;

export default () => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTransitioning(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // 現在の言語に基づいてメッセージ画像を選択
  const messageImage = i18n.language === 'ja' ? messageImageJa : messageImageEn;

  return (
    <>
      <InitialLogoContainer fadeOut={isTransitioning}>
        <InitialLogo src={wideLogo} alt="Conoty Wide Logo" />
      </InitialLogoContainer>

      <Container visible={isTransitioning}>
        <HeroContainer>
          <Content>
            <ImageContainer>
              <ImageWrapper>
                <Message 
                  src={messageImage} 
                  alt={i18n.language === 'ja' ? "Conotyメッセージ" : "Conoty Message"} 
                />
              </ImageWrapper>
              <ImageWrapper>
                <Logo src={logoImage} alt="Conoty Square-Logo" />
              </ImageWrapper>
            </ImageContainer>
          </Content>
        </HeroContainer>
      </Container>
    </>
  );
};