import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const PolicyText = styled.div`
  ${tw`text-base text-gray-800`}

  p {
    ${tw`mt-0 leading-loose`}
  }
  
  h1 {
    ${tw`text-2xl font-bold mt-10 lg:text-3xl`}
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`}
  }

  h3 {
    ${tw`text-lg font-bold mt-6 lg:text-xl`}
  }

  ul {
    ${tw`list-disc list-inside`}

    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  ${tw`sm:text-sm md:text-base lg:text-lg`}
`;

const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t('terms.title')}</Heading>
          </HeadingRow>
          <PolicyText>
            <h2>{t('terms.application.title')}</h2>
            {t('terms.application.content', { returnObjects: true }).map((text, index) => (
              <p key={index}>{(index + 1) + ". " + text}</p>
            ))}

            <h2>{t('terms.definitions.title')}</h2>
            {t('terms.definitions.content', { returnObjects: true }).map((text, index) => (
              <p key={index}>{(index + 1) + ". " + text}</p>
            ))}

            <h2>{t('terms.serviceOverview.title')}</h2>
            {t('terms.serviceOverview.content', { returnObjects: true }).map((text, index) => (
              <p key={index}>{(index + 1) + ". " + text}</p>
            ))}

            <h2>{t('terms.registration.title')}</h2>
            <p>{t('terms.registration.content')}</p>

            <h2>{t('terms.suspension.title')}</h2>
            <p>{t('terms.suspension.content')}</p>

            <h2>{t('terms.termination.title')}</h2>
            <p>{t('terms.termination.content')}</p>

            <h2>{t('terms.userResponsibility.title')}</h2>
            {t('terms.userResponsibility.content', { returnObjects: true }).map((text, index) => (
              <p key={index}>{(index + 1) + ". " + text}</p>
            ))}

            <h2>{t('terms.prohibitedActions.title')}</h2>
            <p>{t('terms.prohibitedActions.description')}</p>
            <ul>
              {t('terms.prohibitedActions.items', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <h2>{t('terms.serviceSuspension.title')}</h2>
            <p>{t('terms.serviceSuspension.content')}</p>

            <h2>{t('terms.serviceChanges.title')}</h2>
            <p>{t('terms.serviceChanges.content')}</p>

            <h2>{t('terms.disclaimer.title')}</h2>
            <p>{t('terms.disclaimer.description')}</p>
            <ul>
              {t('terms.disclaimer.items', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>

            <h2>{t('terms.rights.title')}</h2>
            {t('terms.rights.content', { returnObjects: true }).map((text, index) => (
              <p key={index}>{(index + 1) + ". " + text}</p>
            ))}

            <h2>{t('terms.privacy.title')}</h2>
            <p>{t('terms.privacy.content')}</p>

            <h2>{t('terms.modifications.title')}</h2>
            <p>{t('terms.modifications.content')}</p>

            <h2>{t('terms.jurisdiction.title')}</h2>
            <p>{t('terms.jurisdiction.content')}</p>

            <h2>{t('terms.userInformation.title')}</h2>
            {t('terms.userInformation.content', { returnObjects: true }).map((text, index) => (
              <p key={index}>{(index + 1) + ". " + text}</p>
            ))}

            <h2>{t('terms.transfer.title')}</h2>
            {t('terms.transfer.content', { returnObjects: true }).map((text, index) => (
              <p key={index}>{(index + 1) + ". " + text}</p>
            ))}

            <p>&nbsp;</p>
            <p>{t('terms.supplementaryProvision.title')}</p>
            <p>{t('terms.supplementaryProvision.date')}</p>
          </PolicyText>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};