import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import prepareImage from "./prepare.png";

const Container = styled.div`
  ${tw`flex justify-center items-center`}
  height: 100vh; /* 画面全体の高さに合わせる */
`;

const Image = styled.img`
  width: 24%;
  margin: 12% 0;

  @media (min-width: 768px) {
    /* タブレット以上のサイズ */
    width: 24%;
    margin: 12% 0;
  }

  @media (min-width: 1024px) {
    /* PC以上のサイズ */
    width: 24%;
    margin: 40px 0;
  }
`;

const Prepare = () => {
  return (
    <Container>
      <Image src={prepareImage} alt="準備中" />
    </Container>
  );
};

export default Prepare;
