import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SwitcherContainer = styled.div`
  ${tw`flex items-center gap-2`}
`;

const Select = styled.select`
  ${tw`p-2 border rounded text-sm focus:outline-none focus:border-orange-500`}
`;

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('language') || 'ja'
  );

  const languages = [
    { code: "en", label: "English" },
    { code: "ja", label: "日本語" },
  ];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    // LocalStorage から言語設定を取得
    const savedLanguage = localStorage.getItem('language');
    
    if (savedLanguage) {
      setCurrentLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    } else {
      // 保存された設定がない場合はブラウザの言語を使用
      const browserLanguage = navigator.language || navigator.userLanguage;
      const defaultLanguage = browserLanguage.startsWith("ja") ? "ja" : "en";
      
      setCurrentLanguage(defaultLanguage);
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem('language', defaultLanguage);
    }
  }, [i18n]);

  // 選択中の言語を先頭に並び替え
  const sortedLanguages = [
    ...languages.filter((lang) => lang.code === currentLanguage),
    ...languages.filter((lang) => lang.code !== currentLanguage),
  ];

  return (
    <SwitcherContainer>
      <label htmlFor="language-select">
        {currentLanguage === 'ja' ? '言語' : 'Language'}
      </label>
      <Select
        id="language-select"
        onChange={(e) => changeLanguage(e.target.value)}
        value={currentLanguage}
      >
        {sortedLanguages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.label}
          </option>
        ))}
      </Select>
    </SwitcherContainer>
  );
};

export default LanguageSwitcher;