import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useState, useEffect } from 'react';

import en from "./locales/en.json";
import ja from "./locales/ja.json";

const resources = {
  en: { translation: en },
  ja: { translation: ja },
};

const getBrowserLanguage = () => {
  if (typeof window === 'undefined') return 'en';
  
  const savedLanguage = localStorage.getItem('userLanguage');
  if (savedLanguage) {
    return savedLanguage;
  }
  
  const userLanguage = navigator.language || navigator.userLanguage;
  const isJapanese = userLanguage.toLowerCase().startsWith('ja');
  const detectedLanguage = isJapanese ? 'ja' : 'en';
  
  localStorage.setItem('userLanguage', detectedLanguage);
  return detectedLanguage;
};

const initialLanguage = getBrowserLanguage();

const i18nConfig = {
  resources,
  lng: initialLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true, // returnObjectsを有効化
  debug: process.env.NODE_ENV === 'development',
  react: {
    useSuspense: true // waitオプションを削除し、useSuspenseのみを使用
  }
};

if (!i18n.isInitialized) {
  i18n.use(initReactI18next).init(i18nConfig);
}

export const changeLanguage = async (language) => {
  try {
    localStorage.setItem('userLanguage', language);
    await i18n.changeLanguage(language);
    return true;
  } catch (error) {
    console.error('Language change failed:', error);
    return false;
  }
};

export const useLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setCurrentLanguage(lng);
    };

    if (i18n.language !== initialLanguage) {
      changeLanguage(initialLanguage);
    }

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return currentLanguage;
};

export default i18n;