import React from "react";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import GuideList from "components/how-to-use/how-to-use-list";
import HowToUseTitle from "components/how-to-use/how-to-use-title";


export default () => {
  return (
    <>
      <Header />
      <HowToUseTitle />
      <GuideList />
      <Footer />
    </>
  );
};
