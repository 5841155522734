import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";
import { Link } from "react-router-dom";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-base text-gray-800`}

  p {
    ${tw`mt-0 leading-loose`}
  }

  h1 {
    ${tw`text-2xl font-bold mt-10 lg:text-3xl`}
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`}
  }

  ul {
    ${tw`list-disc list-inside`}

    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  ${tw`sm:text-sm md:text-base lg:text-lg`}
`;

const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

const StyledLink = tw(Link)`
  border-b-0
  text-orange-500
  hover:text-orange-500
  hover:border-b-2
  hover:border-orange-500
  transition duration-300
`;

const StyledAnchor = tw.a`
  border-b-0
  text-orange-500
  hover:text-orange-500
  hover:border-b-2
  hover:border-orange-500
  transition duration-300
`;

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t('specialCommercialLaw.heading')}</Heading>
          </HeadingRow>
          <Text>
            <h2>{t('specialCommercialLaw.sections.businessName')}</h2>
            <p>{t('specialCommercialLaw.sections.businessNameValue')}</p>

            <h2>{t('specialCommercialLaw.sections.location')}</h2>
            <p>{t('specialCommercialLaw.sections.locationValue')}</p>

            <h2>{t('specialCommercialLaw.sections.representative')}</h2>
            <p>{t('specialCommercialLaw.sections.representativeValue')}</p>

            <h2>{t('specialCommercialLaw.sections.price')}</h2>
            <p>{t('specialCommercialLaw.sections.priceValue')}</p>

            <h2>{t('specialCommercialLaw.sections.additionalFees')}</h2>
            <p>{t('specialCommercialLaw.sections.additionalFeesValue')}</p>

            <h2>{t('specialCommercialLaw.sections.paymentMethod')}</h2>
            <p>{t('specialCommercialLaw.sections.paymentMethodValue')}</p>

            <h2>{t('specialCommercialLaw.sections.serviceStart')}</h2>
            <p>{t('specialCommercialLaw.sections.serviceStartValue')}</p>

            <h2>{t('specialCommercialLaw.sections.cancellation')}</h2>
            {t('specialCommercialLaw.sections.cancellationValue', { returnObjects: true }).map((text, index) => (
              <p key={index}>{text}</p>
            ))}
            <ul>
              <li>
                <StyledAnchor 
                  href="https://support.apple.com/ja-jp/HT202039" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {t('specialCommercialLaw.sections.iosCancel')}
                </StyledAnchor>
              </li>
              <li>
                <StyledAnchor 
                  href="https://support.google.com/googleplay/answer/7018481" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {t('specialCommercialLaw.sections.androidCancel')}
                </StyledAnchor>
              </li>
            </ul>

            <h2>{t('specialCommercialLaw.sections.returns')}</h2>
            <p>{t('specialCommercialLaw.sections.returnsValue')}</p>

            <h2>{t('specialCommercialLaw.sections.contact')}</h2>
            <p>
              <StyledLink to="/contact-us">
                {t('specialCommercialLaw.sections.contactValue')}
              </StyledLink>
            </p>

            <h2>{t('specialCommercialLaw.sections.other')}</h2>
            {t('specialCommercialLaw.sections.otherValue', { returnObjects: true }).map((text, index) => (
              <p key={index}>・{text}</p>
            ))}
          </Text>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};