import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";

// HeadingRowにセンター揃えのスタイルを追加
const HeadingRow = tw.div`flex justify-center`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10 text-center`;

const CustomContainer = styled(Container)`
  padding: 80px 24px 8px 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 120px 24px 16px 24px;
  }
`;

export default () => {
  const { t } = useTranslation();

  return (
    <CustomContainer>
      <HeadingRow>
        <Heading>{t('updateNotes')}</Heading>
      </HeadingRow>
    </CustomContainer>
  );
};
