import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

import messageImage1 from "images/conoty-lp-body/conoty-lp-1.png";
import messageImage2 from "images/conoty-lp-body/conoty-lp-2.png";
import messageImage3 from "images/conoty-lp-body/conoty-lp-3.png";
import messageImage4 from "images/conoty-lp-body/conoty-lp-4.png";
import messageImage5 from "images/conoty-lp-body/conoty-lp-5.png";
import messageImage6 from "images/conoty-lp-body/conoty-lp-6.png";

const Container = tw.div`relative`;
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-0`;
const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-2 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded w-full h-80 md:h-144 bg-contain bg-center bg-no-repeat`,
  tw`mx-0 sm:mx-4 md:mx-8`,
  tw`flex-shrink-0 md:w-7/12`
]);

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8 flex-grow`;

const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`lg:text-4xl text-2xl font-bold text-gray-900`;
const Description = tw.p`mt-2 sm:text-xl text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

export default () => {
  const { t } = useTranslation();
  
  const cards = [
    {
      imageSrc: messageImage1,
      subtitle: t('features.timeline.subtitle'),
      title: t('features.timeline.title'),
      description: t('features.timeline.description'),
    },
    {
      imageSrc: messageImage2,
      subtitle: t('features.comments.subtitle'),
      title: t('features.comments.title'),
      description: t('features.comments.description'),
    },
    {
      imageSrc: messageImage3,
      subtitle: t('features.assignee.subtitle'),
      title: t('features.assignee.title'),
      description: t('features.assignee.description'),
    },
    {
      imageSrc: messageImage4,
      subtitle: t('features.attachments.subtitle'),
      title: t('features.attachments.title'),
      description: t('features.attachments.description'),
    },
    {
      imageSrc: messageImage5,
      subtitle: t('features.calendar.subtitle'),
      title: t('features.calendar.title'),
      description: t('features.calendar.description'),
    },
    {
      imageSrc: messageImage6,
      subtitle: t('features.groups.subtitle'),
      title: t('features.groups.title'),
      description: t('features.groups.description'),
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {card.url && (
                  <Link href={card.url} target="_blank" rel="noopener noreferrer">
                    {t('features.supportLink')}
                  </Link>
                )}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};