import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const PolicyText = styled.div`
  ${tw`text-base text-gray-800`}

  p {
    ${tw`mt-0 leading-loose`}
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`}
  }

  ${tw`sm:text-sm md:text-base lg:text-lg`}
`;

const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t('premiumTerms.title')}</Heading>
          </HeadingRow>
          <PolicyText>
            <h2>{t('premiumTerms.aboutTerms.title')}</h2>
            <p>{t('premiumTerms.aboutTerms.content')}</p>

            <h2>{t('premiumTerms.definitions.title')}</h2>
            <p>{t('premiumTerms.definitions.content')}</p>

            <h2>{t('premiumTerms.fees.title')}</h2>
            <p>{t('premiumTerms.fees.content')}</p>

            <h2>{t('premiumTerms.payment.title')}</h2>
            <p>{t('premiumTerms.payment.content')}</p>

            <h2>{t('premiumTerms.cancellation.title')}</h2>
            <p>{t('premiumTerms.cancellation.content')}</p>

            <h2>{t('premiumTerms.termination.title')}</h2>
            <p>{t('premiumTerms.termination.content')}</p>
            <ul>
              {t('premiumTerms.termination.reasons', { returnObjects: true }).map((reason, index) => (
                <li key={index}>{reason}</li>
              ))}
            </ul>
            <p>{t('premiumTerms.termination.disclaimer')}</p>

            <h2>{t('premiumTerms.changes.title')}</h2>
            <p>{t('premiumTerms.changes.content')}</p>

            <h2>{t('premiumTerms.disclaimer.title')}</h2>
            <p>{t('premiumTerms.disclaimer.content')}</p>

            <p>&nbsp;</p>
            <p>{t('premiumTerms.supplementary.title')}</p>
            <p>{t('premiumTerms.supplementary.date')}</p>
          </PolicyText>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};