import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import conotyLogo from "images/conoty-lp-top/conoty-logo-wide.png";
import appStoreBadge from "images/conoty-lp-body/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg";
import googlePlayBadge from "images/conoty-lp-body/GetItOnGooglePlay_Badge_Web_color_Japanese.png";

const HeadingInfoContainer = styled.div`
  ${tw`flex flex-col items-center`}
  margin: 40px 0;
  padding: 0 16px;
`;

const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
const BadgeContainer = tw.div`flex mt-6`;
const AppStoreBadge = tw.img`w-48 mr-4`;
const GooglePlayBadge = tw.img`w-48`;
const Logo = tw.img`w-64`;

const ReleaseText = styled.p`
  ${tw`text-center mt-4`}
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a5568;
`;

const TopMessage = () => {
  const { t } = useTranslation();

  const DownloadButtons = () => (
    <BadgeContainer>
      <AppStoreBadge src={appStoreBadge} alt={t('topMessage.downloadButtons.appStore')} />
      <GooglePlayBadge src={googlePlayBadge} alt={t('topMessage.downloadButtons.googlePlay')} />
    </BadgeContainer>
  );

  return (
    <HeadingInfoContainer>
      <Logo src={conotyLogo} alt="CONOTY Logo" />
      <HeadingDescription>
        {t('topMessage.shortDescription')}
      </HeadingDescription>
      <HeadingDescription>
        {t('topMessage.fullDescription')}
      </HeadingDescription>
      {/* <DownloadButtons /> */}
      <ReleaseText>
        {t('topMessage.releaseDate')}
      </ReleaseText>
    </HeadingInfoContainer>
  );
};

export default TopMessage;