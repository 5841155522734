/* global grecaptcha */

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import LogoSrc from "images/conoty-logo-square-or.png";

const Container = tw.div`relative p-6 md:p-20`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 md:py-24 px-6 md:px-20`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw.div`text-center md:text-left text-orange-500 text-sm font-semibold tracking-wider`;

const Heading = tw.div`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-orange-500 placeholder-gray-600`;
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`;

const SubmitButton = tw.button`inline-block mt-8 px-8 py-3 font-bold rounded bg-orange-500 text-gray-100 hocus:bg-orange-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

const Overlay = tw.div`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex items-center justify-center`;
const Spinner = tw.div`w-16 h-16 border-4 border-t-4 border-orange-500 rounded-full animate-spin`;

export default ({
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
    message: ''
  });
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6Lc6PjcqAAAAAO85z4DHltaHFqAJM2PGRDvTTkO0';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      const recaptchaBadge = document.querySelector('.grecaptcha-badge');
      if (recaptchaBadge) {
        recaptchaBadge.style.display = 'none';
      }
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute('6Lc6PjcqAAAAAO85z4DHltaHFqAJM2PGRDvTTkO0', {action: 'submit'});

      const data = {
        email: formData.email,
        name: formData.name,
        subject: formData.subject,
        message: formData.message,
        'g-recaptcha-response': token
      };

      try {
        await axios.post('https://us-central1-conoty-lp.cloudfunctions.net/sendMail', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        alert(t('contact.alerts.success'));
        setFormData({ email: '', name: '', subject: '', message: '' });
      } catch (error) {
        console.error('メール送信エラー:', error);
        alert(t('contact.alerts.error'));
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <Container>
      {loading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={LogoSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{t('contact.subheading')}</Subheading>
            <Heading>{t('contact.heading')}</Heading>
            <Description>{t('contact.description')}</Description>
            <Form onSubmit={handleSubmit}>
              <Input 
                type="text" 
                name="name" 
                placeholder={t('contact.form.namePlaceholder')} 
                value={formData.name} 
                onChange={handleChange} 
              />
              <Input 
                type="email" 
                name="email" 
                placeholder={t('contact.form.emailPlaceholder')} 
                value={formData.email} 
                onChange={handleChange} 
              />
              <Input 
                type="text" 
                name="subject" 
                placeholder={t('contact.form.subjectPlaceholder')} 
                value={formData.subject} 
                onChange={handleChange} 
              />
              <Textarea 
                name="message" 
                placeholder={t('contact.form.messagePlaceholder')} 
                value={formData.message} 
                onChange={handleChange} 
              />
              <SubmitButton type="submit" disabled={loading}>
                {t('contact.form.submitButton')}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};