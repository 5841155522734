import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Prepare from "components/preparing/Prepare";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import MaintenanceTitle from "components/maintenance/maintenance-title";
import UpdateNotesContens from "components/maintenance/maintenance-contents";


export default () => {
    return (
        <>
          <Header />
          <div>
            <MaintenanceTitle />
            <UpdateNotesContens />
          </div>
          <Footer />
        </>
      );
    };
    