import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/lp-header";
import Footer from "components/footers/lp-footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-base text-gray-800`}

  p {
    ${tw`mt-0 leading-loose`}
  }

  h1 {
    ${tw`text-2xl font-bold mt-10 lg:text-3xl`}
  }

  h2 {
    ${tw`text-xl font-bold mt-8 lg:text-2xl`}
  }

  h3 {
    ${tw`text-lg font-bold mt-6 lg:text-xl`}
  }

  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  ${tw`sm:text-sm md:text-base lg:text-lg`}
`;

const CustomContainer = styled(Container)`
  padding: 24px;
  max-width: 784px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <CustomContainer>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t('privacyChoices.title')}</Heading>
          </HeadingRow>
          <Text>
            <h2>{t('privacyChoices.tracking.title')}</h2>
            <p>{t('privacyChoices.tracking.description')}</p>
            <ul>
              <li>
                <strong>{t('privacyChoices.tracking.ios.title')}</strong>
                <p>{t('privacyChoices.tracking.ios.instructions')}</p>
              </li>
              <li>
                <strong>{t('privacyChoices.tracking.android.title')}</strong>
                <p>{t('privacyChoices.tracking.android.instructions')}</p>
              </li>
            </ul>

            <h2>{t('privacyChoices.dataCollection.title')}</h2>
            <p>{t('privacyChoices.dataCollection.description')}</p>
            <ul>
              {t('privacyChoices.dataCollection.items', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p>{t('privacyChoices.dataCollection.optOut')}</p>
            <p>{t('privacyChoices.dataCollection.email')}</p>

            <h2>{t('privacyChoices.adId.title')}</h2>
            <p>{t('privacyChoices.adId.description')}</p>
            <ul>
              <li>
                <strong>{t('privacyChoices.adId.ios.title')}</strong>
                <p>{t('privacyChoices.adId.ios.instructions')}</p>
              </li>
              <li>
                <strong>{t('privacyChoices.adId.android.title')}</strong>
                <p>{t('privacyChoices.adId.android.instructions')}</p>
              </li>
            </ul>

            <h2>{t('privacyChoices.contact.title')}</h2>
            <p>{t('privacyChoices.contact.description')}</p>
            <p>{t('privacyChoices.contact.company')}</p>
            <p>{t('privacyChoices.contact.address')}</p>
            <p>{t('privacyChoices.contact.email')}</p>
          </Text>
        </ContentWithPaddingXl>
      </CustomContainer>
      <Footer />
    </>
  );
};