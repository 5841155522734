import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Header from "components/headers/lp-header-top";
import Footer from "components/footers/lp-footer";

import AboutUsTopImage from "images/conoty-lp-about-us/about-us-top.png";
import AinnaLogoJa from "images/conoty-lp-about-us/ainna-logo-wh-ja.png";
import AinnaLogoEn from "images/conoty-lp-about-us/ainna-logo-wh-en.png";

const TopImage = tw.img`w-full h-auto`;

const LogoImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: fadeIn 1s ease-in-out 1s forwards;

  @media (min-width: 1024px) {
    width: 32%;
  }

  @media (max-width: 1023px) {
    width: 56%;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TextSection = styled.div`
  background-color: #f7f7f7;
  color: #333;
  text-align: center;
  padding: 40px 20px;
  
  p {
    font-size: 14px;
    margin: 4px 0;
    line-height: 1.6;
  }
`;

const MessageSection = styled.div`
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: 40px 20px;

  h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    line-height: 1.8;
  }
`;

export default () => {
  const { t, i18n } = useTranslation();

  // 言語に応じてロゴ画像を選択
  const logoImage = i18n.language === 'ja' ? AinnaLogoJa : AinnaLogoEn;

  return (
    <>
      <Header />
      <ImageContainer>
        <TopImage src={AboutUsTopImage} alt={t("about_us.title")} />
        <LogoImage 
          src={logoImage} 
          alt={i18n.language === 'ja' ? "AINNAロゴ" : "AINNA Logo"} 
        />
      </ImageContainer>

      <TextSection>
        <p>{t("about_us.title")}</p>
        <p>---</p>
        <p>&nbsp;</p>
        <p>{t("about_us.company_name")}</p>
        <p>{t("about_us.representative")}</p>
        <p>{t("about_us.ceo")}</p>
        <p>&nbsp;</p>
        <p>{t("about_us.address")}</p>
        <p>&nbsp;</p>
        <p>{t("about_us.established")}</p>
        <p>&nbsp;</p>
        <p>{t("about_us.business_overview")}</p>
        {t("about_us.business_description", { returnObjects: true }).map((desc, index) => (
          <p key={index}>{desc}</p>
        ))}
        <p>&nbsp;</p>
        <p>{t("about_us.bank")}</p>
      </TextSection>

      <MessageSection>
        <p>{t("message.title")}</p>
        <p>---</p>
        <p>&nbsp;</p>
        <p>{t("message.slogan")}</p>
        <p>{t("message.name")}</p>
        <p>&nbsp;</p>
        {t("message.paragraphs", { returnObjects: true }).map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        <p>&nbsp;</p>
        <p>{t("message.ceo_signature")}</p>
      </MessageSection>

      <Footer />
    </>
  );
};