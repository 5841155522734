import React from "react";
import tw from "twin.macro";
import styled from "styled-components/macro"; // eslint-disable-line
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Container = tw.div`relative bg-gray-200 mt-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-8 flex flex-col items-center`;

const Column = tw.div`md:w-full flex flex-col items-center my-4`;
const ColumnHeading = tw.h5`font-bold mb-4 text-center`;

const LinkList = styled.ul`
  ${tw`flex justify-center text-sm font-medium space-x-4`}
  @media (max-width: 640px) {
    ${tw`flex-col space-x-0 space-y-2`}
  }
`;

const LinkListItem = styled.li`
  @media (max-width: 640px) {
    ${tw`text-center`}
  }
`;

const StyledLink = tw(Link)`
  border-b-0
  text-gray-800
  hover:text-orange-500
  transition duration-300
`;

const Copyright = tw.p`text-center mt-4 text-sm text-gray-600 pb-8`;

export default () => {
  const { t } = useTranslation();

  console.log("Rendering Footer with translations:", {
    userSupport: t("userSupport"),
    faqs: t("faqs"),
    updateNotes: t("updateNotes"),
    contactUs: t("contactUs"),
    terms: t("terms"),
  });

  return (
    <Container>
      <FiveColumns>
        <Column>
          <ColumnHeading>{t("userSupport")}</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <StyledLink to="/howtouse">{t("howtouse")}</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/Maintenance">{t("updateNotes")}</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/contact-us">{t("contactUs")}</StyledLink>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>{t("title_terms")}</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <StyledLink to="/terms-of-service">{t("termsOfService")}</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/terms-of-service-premium">
                {t("termsOfServicePremium")}
              </StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/privacy-policy">{t("privacyPolicy")}</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/user-privacy-choice">{t("userPrivacyChoice")}</StyledLink>
            </LinkListItem>
            <LinkListItem>
              <StyledLink to="/legal-notice">{t("legalNotice")}</StyledLink>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>{t("company")}</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <StyledLink to="/about-us">{t("companyName")}</StyledLink>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <Copyright>{t("copyright")}</Copyright>
    </Container>
  );
};
