import React from 'react';
import { useTranslation } from "react-i18next";



const UpdateNotesContens = () => {
  const { t } = useTranslation();
  return (
    <div style={styles.container}>
      <p style={styles.message}>{t('preparing-maintenance')}</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40vh',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '2em',
    color: '#333',
  },
  message: {
    fontSize: '1.2em',
    color: '#666',
    textAlign: 'center',
    maxWidth: '600px',
    lineHeight: '1.5',
  },
};

export default UpdateNotesContens;
